<template>
  <v-container>
    <notification-popup
      :dialog="notifFailed"
      :contentMsg="$t('FailedDoPayment')"
      :headerMsg="$t('Failed')"
      :nameBtnNo="$t('Cancel')"
      :nameBtnYes="$t('TryAgain')"
      colorIcon="danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerYes="onLoadAction"
      :onHandlerNo="onCancel"
    />

    <v-overlay :value="isLoading">
        <v-progress-circular
          :value="80"
          :size="70"
          :width="7"
          color="green"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    <v-row>
      <v-col cols="12" class="pa-0">
        <div id="iframe-container"></div>
      </v-col>
      <v-col cols="12">
        <v-btn
          v-show="showMicroform"
          type="submit"
          id="submit-button"
          rounded
          block
          class="text-capitalize primary"
        >
          {{ $t("Pay") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
export default {
  components: { NotificationPopup },
  data() {
    return {
      microformSouceCode:
        "https://alepay-v3-sandbox.nganluong.vn/pay/microform/web/js/1.0/alepay-microform.js",
      targetOrigin: "https://alepay-v3-sandbox.nganluong.vn",
      cardRequestToken: "",
      env: "dev",
      showMicroform: false,
      sendOrderNotif: true,
      showRequestToken: true,
      showSendOrder: false,
      trx_Detail:null,
      isLoading:true,
      notifFailed: false
    };
  },
  methods: {
    hideMicroform() {
      this.showMicroform = false
    },
    hideRequestToken() {
      this.showRequestToken = false;
    },
    displayMicroform() {
      this.showMicroform = true;
    },
    getmicroform() {
      var submitButton = document.querySelector("#submit-button");
      var sendOrder = this.sendOrder;
      
      var displayMicroform = this.displayMicroform;
      var hideRequestToken = this.hideRequestToken;
      
      Microform.init(
        {
          container: "#iframe-container",
          targetOrigin: this.targetOrigin,
          cardRequestToken: this.cardRequestToken,
          lang: "en",
          viewType: "web",
          renderIframe: true,
        },
        function (initError, microformInstance) {
          if (initError) {
            return;
          } else {
            hideRequestToken();
            displayMicroform();
          }
          submitButton.addEventListener("click", function (e) {
            e.preventDefault();
            microformInstance.createToken(1, function (error, response) {
              if (error !== null) {
                alert(error.message);
                return;
              }
              let orderPayload = {
                token: response.token,
                bankCode: response.bankCode,
                bankName: response.bankName,
                cardNumber: response.cardNumber,
                paymentMethod: response.paymentMethod,
              };
              sendOrder(orderPayload);
            });
          });
        }
      );
    },
    sendOrder(sendOrderData){
      var token = this.$route.params.payment_token
      this.showSpinner = true
      let newPayload = {
          requestToken: {
              cardRequestToken: this.cardRequestToken
          },
          sendOrder: sendOrderData
      }
      var params = {};
      params.token=token
      params.payload=newPayload

      this.$store.dispatch("payment/sendOrderAlepay", params).then((response) => {
        if(response.status_code == 200){
          var dataresp = JSON.parse(window.atob(response.data.body));
          this.hideMicroform()
          if (response.data.response_code == "4476") {
            if(this.trx_Detail.channel_code == "alepay-credit_debit"){
              window.location.replace(dataresp.data.linkRedirect3D);
            } 
          }
          this.isLoading = false
        } else {
          this.hideMicroform()
          this.isLoading = false
        }
      })
    },
    onLoadAction() {
      this.notifFailed = false

      this.env = process.env.VUE_APP_ENV_VARIABLE;
      this.microformSouceCode="https://alepay-v3-sandbox.nganluong.vn/pay/microform/web/js/1.0/alepay-microform.js"
      this.targetOrigin=window.location.origin

      this.trx_Detail = JSON.parse(localStorage.getItem("trxDetail"))
      var payload = {
        transaction_splitting_formula_id:this.trx_Detail.transaction_splitting_formula_id,
        gross_amount:this.trx_Detail.gross_amount,
        payment_channels_be_id : ""+this.trx_Detail.payment_channels_be_id+""
      }

      var params = {};
      params.payload = payload
      params.token = this.$route.params.payment_token
      var paymentSelected;
      if(this.trx_Detail.channel_code == "alepay-credit_debit"){
        paymentSelected = "choosePaymentAlepay"
      } else if (this.trx_Detail.channel_code == "ALEPAY-serverbase"){
        paymentSelected = "choosePaymentAlepayATMCard"
      } else if (this.trx_Detail.channel_code == "momo-credit_debit"){
        paymentSelected = "choosePaymentMomo"
      } else if (this.trx_Detail.channel_code == "moca-credit_debit"){
        paymentSelected = "choosePaymentMoca"
      }

      this.$store.dispatch(`payment/${paymentSelected}`, params)
        .then((response) => {
          if(response.status_code == 200){
            var dataresp = JSON.parse(window.atob(response.data.body));
            if (response.data.response_code == "4401") {
              this.cardRequestToken = dataresp.data.cardRequestToken;
              this.getmicroform();
            } else if (response.data.response_code == "4476") {
              if(this.trx_Detail.channel_code == "alepay-credit_debit"){
                window.location.replace(dataresp.data.linkRedirect3D)
              } else if(this.trx_Detail.channel_code == "ALEPAY-serverbase") {
                window.location.replace(dataresp.checkoutUrl)
              } else if(this.trx_Detail.channel_code == "momo-credit_debit") {
                window.location.replace(dataresp.payUrl);
              } else if(this.trx_Detail.channel_code == "moca-credit_debit") {
                window.location.replace(dataresp.moca.web_url)
              }
            } else {
              this.$router.replace({ name: "PaymentStatus", params: { payment_token: params.token }, });
            }
          } else {
            this.$router.replace({ name: "PaymentStatus", params: { payment_token: params.token }, });
          }
          this.isLoading = false
        })
        .catch(err => {
          console.log(err)
          this.notifFailed = true
        })
    },
    onCancel() {
      this.$router.go(-1)
    }
  },
  created() {
    this.onLoadAction()
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://alepay-v3-sandbox.nganluong.vn/pay/microform/web/js/1.0/alepay-microform.js");
    document.head.appendChild(externalScript);
  },
};
</script>

<style scoped>
#iframe-container {
  height: 500px;
}
</style>